import React from 'react'
import Layout from '../components/organisms/Layout/Layout'
import Container from '../components/atoms/Container/Container'
import Breadcrumbs from '../components/atoms/Breadcrumbs/Breadcrumbs'
import Seo from '../components/organisms/Seo/Seo';
import HubspotContactUsForm from '../components/molecules/HubspotContactUsForm/HubspotContactUsForm';

import * as styles from './contact-us.module.css'
import { graphql } from 'gatsby';

const ContactUs = ({ data }) => {
    
    const page = (data && data.contactPage) || false;
    const seoTitle = page.seoTitle || page.pageName;
    return (  
        <div>
            <Layout displaySubscribe="true">
                <Seo title={seoTitle} description={page.metaDescription} image={page.metaPreviewImage ? `https:${page.metaPreviewImage.file.url}` : ''}/>
                <Container size="medium">
                    <Breadcrumbs crumbs={[{link: '/', label:'Home'}, {label:`${page.pageTitle}`}]} />
                    <div className={styles.contactUs}>
                        <h1 className="h4">{page.pageTitle}</h1>
                        <p>{page.metaDescription}</p>
                        <div className={styles.infoWrapper}>
                            <div>
                                <img src={page.blocks[0].blocks[0].desktopImage.file.url} alt="Contact Us"/>
                            </div>
                            <div className={styles.infoBoxContainer}>
                                <div className={styles.infoBox}>
                                    <div className={styles.infoTitle}>We're here to help</div>
                                    <p>Please fill out your details below so we can best help you.</p>
                                </div>
                                <div className={styles.infoBox}>
                                    <div className={styles.infoTitle}>Our Offices</div>
                                    <div className={styles.infoAddress}>
                                        <div>LAVO Australia</div>
                                        <div>Level 9 / 120 Sussex St, Sydney, Australia</div>
                                    </div>
                                    <div className={styles.infoAddress}>
                                        <div>LAVO Germany</div>
                                        <div>Kemperplatz 1, 10785 Berlin, Germany</div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className={styles.subtitle}>Fill out the form below to get in touch.</div>
                        <div className={styles.hubspotContainer}><HubspotContactUsForm /></div>
                    </div>
                    
                </Container>
            </Layout>
        </div>
    );
}
 
export default ContactUs;

export const query = graphql`
query ContentfulPageContactUs {
    contactPage: contentfulPage(pageName: {eq: "Contact Us"}) {
        pageName
        pageTitle
        metaCanonicalLink
        metaDescription
        metaTitle
        seoTitle
        metaPreviewImage{
          file {
            url
          }
        }
        blocks {
            blocks {
                ... on ContentfulBlockImage {
                desktopImage {
                    gatsbyImageData
                    file {
                    url
                    }
                }
                mobileImage {
                    gatsbyImageData
                    file {
                    url
                    }
                }
                }
            }
        }
    }
}
`