import React, { useEffect, useState } from 'react';
import { CountryRegionData } from 'react-country-region-selector';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { hubspotApi } from '../../../helpers/hubspot';
import Button from '../../atoms/Button/Button';
import FormInputField from '../../atoms/FormInputField/FormInputField';
import { validateEmail, getCookie } from '../../../helpers/general';
import Dialog from '../../atoms/Dialog/Dialog';

import * as styles from './HubspotContactUsForm.module.css';
import ArrowDown from '../../atoms/Icon/arrowDown';

const validForm = data => {
  // const labels = {
  //   first_name: 'First name',
  //   last_name: 'Last name',
  //   email: 'Work email',
  //   company: 'Company',
  //   website: 'Company website',
  //   enquiry_type: 'Enquiry type',
  //   message: 'Message',
  // };
  const errorBag = {};
  if (!data.first_name) {
    errorBag['first_name'] = 'First name is required.';
  }
  if (!data.last_name) {
    errorBag['last_name'] = 'Last name is required.';
  }
  if (!data.email) {
    errorBag['email'] = 'Work email is required.';
  }
  if (!validateEmail(data.email)) {
    errorBag['email'] = 'Invalid email address.';
  }
  if (!data.enquiry_type) {
    errorBag['enquiry_type'] = 'Subject is required.';
  }
  if (!data.message) {
    errorBag['message'] = 'Message is required.';
  }
  if (!data.terms) {
    errorBag['terms'] = 'Please read & agree to terms and conditions.';
  }
  if (data.enquiry_type !== 'LAVO Life') {
    if (!data.company) {
      errorBag['company'] = 'Company is required.';
    }
    if (!data.website) {
      errorBag['website'] = 'Company website is required.';
    }
  }
  return errorBag;
};

const HubspotContactUsForm = () => {
  // console.log(getCookie('hubspotutk'));
  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    company: '',
    website: '',
    country: '',
    enquiry_type: '',
    industry: '',
    what_is_your_energy_objective_: '',
    message: '',
    other: '',
    grid_connection___off_grid_: '',
    pre_ordered_lavo_battery: '',
  });
  const [countryData, setCountryData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [termsPop, setTermsPop] = useState(false);
  const [msg, setMessage] = useState({
    error: {},
    success: '',
    dialogSuccess: '',
    dialogError: '',
  });
  const onHandleChange = (id, value) => {
    let tempValues = Object.assign({}, values);
    tempValues[id] = value;
    if (id === 'terms') {
      tempValues[id] = value.checked;
      setCheckedTerms(value.checked);
    }

    if (id === 'pre_ordered_lavo_battery') {
      tempValues[id] = value.checked;
    }
    setValues(tempValues);
  };
  // const onCountryChange = (value) => {
  //   onHandleChange('country',value);
  // };
  useEffect(() => {
    const countries = CountryRegionData.reduce((acc, curr) => {
      acc.push({
        id: curr[1],
        name: curr[0],
      });
      return acc;
    }, []);
    setCountryData(countries);
  }, []);
  const submitContactUsInfo = () => {
    if (!checkedTerms) {
      setTermsPop(true);
    }

    const validationMsg = validForm(values);
    if (Object.keys(validationMsg).length) {
      setMessage({ error: validationMsg });
      return;
    }

    const {
      first_name,
      last_name,
      email,
      company,
      website,
      country,
      enquiry_type,
      industry,
      what_is_your_energy_objective_,
      message,
      grid_connection___off_grid_,
      pre_ordered_lavo_battery,
    } = values;
    // terms
    let fields = [
      {
        name: 'email',
        value: email,
      },
      {
        name: 'firstname',
        value: first_name,
      },
      {
        name: 'lastname',
        value: last_name,
      },
      {
        name: 'company',
        value: company,
      },
      {
        name: 'website',
        value: website,
      },
      {
        name: 'country',
        value: country,
      },
      {
        name: 'enquiry_type',
        value: enquiry_type,
      },
      {
        name: 'what_is_your_energy_objective_',
        value: what_is_your_energy_objective_,
      },
      {
        name: 'message',
        value: message,
      },
      {
        name: 'pre_ordered_lavo_battery',
        value: pre_ordered_lavo_battery,
      },
    ];
    if (grid_connection___off_grid_) {
      fields.push({
        name: 'grid_connection___off_grid_',
        value: grid_connection___off_grid_,
      });
    }

    if (industry === 'Other' && values.other !== '') {
      fields.push({
        name: 'industry',
        value: values.other,
      });
    } else {
      fields.push({
        name: 'industry',
        value: industry,
      });
    }

    const body = {
      fields: fields,
      context: {
        hutk: getCookie('hubspotutk'), // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
      },
    };

    hubspotApi(`hubspot`, 'POST', body).then(response => {
      if ((response && response.status === 200) || response.status === 201) {
        setMessage({
          error: {},
          dialogSuccess:
            'We love connecting with people curious about green energy so thanks for your submission!',
        });
        setDialogOpen(true);
        setValues({
          first_name: '',
          last_name: '',
          email: '',
          company: '',
          website: '',
          country: '',
          industry: '',
          enquiry_type: '',
          message: '',
          pre_ordered_lavo_battery: false,
          terms: false,
        });
      } else {
        setDialogOpen(true);
        setMessage({
          dialogError: 'Sorry, something went wrong. Try again later.',
        });
      }
    });
  };

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
  };

  const handleOnHover = result => {
    // the item hovered
  };

  const handleOnSelect = item => {
    // the item selected
    onHandleChange('country', item.name);
  };

  // const handleOnFocus = () => {
  //   // console.log('Focused')
  // }

  const formatResult = item => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
      </>
    );
  };

  const choices = [
    'Business Solution',
    'Government Enquiry',
    'General Enquiry',
    'Media Enquiry',
    'LAVO Life',
  ]; // 'Investor Enquiry',
  const radioChoices = [
    'Utility',
    'Commercial + Industrial',
    'Emergency/Back-up power',
    'Other',
  ]; // 'Telco',
  // const checkboxChoices = ['Telco - Off grid', 'Telco - Bad grid', 'Telco - On grid']
  return (
    <div className={styles.contactUsFormContainer}>
      {/*{msg.error ? <p className={`${styles.error}`}>{msg.error}</p>}*/}
      <div
        className={`grid grid-50 ${styles.inputGridGap} ${styles.inputGridWrapper}`}>
        <div>
          <FormInputField
            id='first_name'
            type='input'
            labelName='First name'
            value={values['first_name']}
            handleChange={onHandleChange}
          />
          {Object.keys(msg.error).length && msg.error.first_name ? (
            <div className={styles.error}>{msg.error.first_name}</div>
          ) : (
            ''
          )}
        </div>
        <div>
          <FormInputField
            id='last_name'
            type='input'
            labelName='Last name'
            value={values['last_name']}
            handleChange={onHandleChange}
          />
          {Object.keys(msg.error).length && msg.error.last_name ? (
            <div className={styles.error}>{msg.error.last_name}</div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div
        className={`grid grid-50 ${styles.inputGridGap} ${styles.inputGridWrapper}`}>
        <div>
          <FormInputField
            id='email'
            type='input'
            labelName='Work email'
            value={values['email']}
            handleChange={onHandleChange}
          />
          {Object.keys(msg.error).length && msg.error.email ? (
            <div className={styles.error}>{msg.error.email}</div>
          ) : (
            ''
          )}
        </div>
        <div>
          <FormInputField
            id='company'
            type='input'
            labelName='Company'
            value={values['company']}
            handleChange={onHandleChange}
          />
          {Object.keys(msg.error).length && msg.error.company ? (
            <div className={styles.error}>{msg.error.company}</div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div
        className={`grid grid-50 ${styles.inputGridGap} ${styles.inputGridWrapper}`}>
        <div>
          <FormInputField
            id='website'
            type='input'
            labelName='Company website'
            value={values['website']}
            handleChange={onHandleChange}
          />
          {Object.keys(msg.error).length && msg.error.website ? (
            <div className={styles.error}>{msg.error.website}</div>
          ) : (
            ''
          )}
        </div>

        <div>
          <label className={styles.countryLabel} htmlFor='country'>
            Country
          </label>
          <ReactSearchAutocomplete
            items={countryData}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            // onFocus={handleOnFocus}
            autoFocus // eslint-disable-line
            formatResult={formatResult}
            showIcon={false}
            styling={{
              padding: '10px',
              border: '1px solid #ccc',
              fontSize: '16px',
              borderRadius: '15px',
              height: '50px',
              position: 'relative',
              zIndex: 2,
            }}
          />
        </div>
      </div>
      <div>
        <FormInputField
          id='enquiry_type'
          type='dropdown'
          labelName='I am contacting you about'
          value={values['enquiry_type']}
          handleChange={onHandleChange}
          choices={choices}
          rightIcon={<ArrowDown fill='#7B2CBF' />}
        />
        {Object.keys(msg.error).length && msg.error.enquiry_type ? (
          <div className={styles.error}>{msg.error.enquiry_type}</div>
        ) : (
          ''
        )}

        {values['enquiry_type'] === 'Business Solution' && (
          <div className={styles.radioGroup}>
            <FormInputField
              id='industry'
              type='radiogroup'
              labelName='What is your planned area of application?'
              value={values['industry']}
              handleChange={onHandleChange}
              choices={radioChoices}
              subOption={{
                value: 'Telco',
                name: 'grid_connection___off_grid_',
                choices: ['Off grid', 'Bad grid', 'On grid'],
              }}
            />
            {values['industry'] === 'Other' && (
              <div className={styles.otherIndustry}>
                <FormInputField
                  id='other'
                  type='text'
                  labelName='Please Specify '
                  value={values['other']}
                  handleChange={onHandleChange}
                />
              </div>
            )}
            {values['enquiry_type'] === 'Business Solution' && (
              <FormInputField
                id='what_is_your_energy_objective_'
                type='textarea'
                labelName='What is your energy objective? '
                value={values['what_is_your_energy_objective_']}
                placeholder={`For example,
• Replace or displace diesel generation
• Increase usable renewable energy capacity/mitigate renewable energy curtailment
• Achieve grid independence
• Long duration energy storage (e.g. seasonal)`}
                handleChange={onHandleChange}
              />
            )}
          </div>
        )}

        <FormInputField
          id='message'
          type='textarea'
          labelName='What is your enquiry?'
          value={values['message']}
          handleChange={onHandleChange}
        />
        {Object.keys(msg.error).length && msg.error.message ? (
          <div className={styles.error}>{msg.error.message}</div>
        ) : (
          ''
        )}
        <div className={`${styles.checkboxes}`}>
          <FormInputField
            id='pre_ordered_lavo_battery'
            type='checkbox'
            labelName='I am a current LAVO Pre-Order Customer'
            value={values['pre_ordered_lavo_battery']}
            handleChange={onHandleChange}
          />
        </div>
        <div className={`${styles.termsAndConditions} ${styles.checkboxes}`}>
          <FormInputField
            id='terms'
            type='checkbox'
            labelName="I agree to <a href='/terms-and-conditions' target='_blank'>terms and conditions</a>"
            value={values['terms']}
            handleChange={onHandleChange}
          />
        </div>
      </div>
      <div className={`row ${styles.buttonWrapp}`}>
        <Button
          type='span'
          level='primary'
          fullWidth={true}
          onClick={() => submitContactUsInfo()}>
          Submit
        </Button>
      </div>

      <Dialog
        hideBtnCancel={true}
        size='sm'
        open={dialogOpen}
        hideBtnOk={false}
        title='Hello Energy Enthusiast. Nice To Meet You!'
        onCancel={() => setDialogOpen(false)}
        okBtnText={'Thanks!'}>
        {msg.dialogSuccess && (
          <p className={styles.dialogContent}>{msg.dialogSuccess}</p>
        )}
        {msg.dialogError && (
          <p className={styles.dialogContent}>{msg.dialogError}</p>
        )}
      </Dialog>

      <Dialog
        hideBtnCancel={true}
        size='sm'
        open={termsPop}
        hideBtnOk={false}
        title='Hello Energy Enthusiast.'
        onOk={() => setTermsPop(false)}
        okBtnText={'Close'}>
        Please tick the box to indicate that you agree to our terms presented in
        the{' '}
        <a href='/terms-and-conditions' target='_blank'>
          Terms and Conditions agreement
        </a>
      </Dialog>
    </div>
  );
};

export default HubspotContactUsForm;

